import {AlertColor} from "@mui/lab";
import {
	Alert,
	Box,
	Button,
	ButtonGroup,
	Chip,
	Dialog,
	DialogActions,
	DialogTitle,
	Grid,
	Link,
	List,
	ListItem,
	ListItemText,
	Paper,
	Snackbar,
	Stack,
	Tooltip,
	Typography,
} from "@mui/material";
import {BreadcrumbItem, Breadcrumbs, ContainerLayout, TemporalFormat, tryParsePlainDate} from "@variocube/app-ui";
import {NotFound} from "@variocube/app-ui/esm/layout/NotFound";
import * as qs from "qs";
import {Component, createElement, Fragment} from "react";
import {NavigateFunction} from "react-router/dist/lib/hooks";
import {DeliveriesProvider} from "../../domain/DeliveriesProvider";
import {
	Delivery,
	DeliveryQueueItem,
	DeliveryState,
	DeliveryType,
	Recipient,
	recipientSecondaryText,
} from "../../domain/Delivery";
import {OutOfOfficeProvider} from "../../domain/OutOfOfficeProvider";
import {Tenant, User} from "../../domain/TenantUser";
import {useLocalization} from "../../i18n";
import {gs} from "../../theme";
import {shorten} from "../../tools";
import {ActionMenuItem, ActionsMenu} from "../ActionsMenu";
import {BoxedPaper} from "../BoxedPaper";
import {BreadcrumbRouterLink} from "../BreadcrumbRouterLink";
import {ConfirmDialog} from "../ConfirmDialog";
import {DateComponent, Display} from "../DateComponent";
import {HelmetTitleWrapper} from "../HelmetTitleWrapper";
import {LabeledData} from "../LabeledData";
import {LabeledItem} from "../LabeledItem";
import {Loading} from "../Loading";
import {IPrintViewConfig, Printable, PrintablesEnum as Field} from "../settings/PrintViewConfig";
import {UserNameDisplay} from "../UserNameDisplay";
import {WebSocketMessageConsumer} from "../WebSocketConnectionContextProvider";
import {CodeDisplay} from "./CodeDisplay";
import {DeliveryAuditLog} from "./DeliveryAuditLog";
import {DeliveryBoxComponent} from "./DeliveryBoxComponent";
import {DeliveryConditionDisplay} from "./DeliveryConditionDisplay";
import {DeliveryIdDisplay} from "./DeliveryIdDisplay";
import {DeliveryImage} from "./DeliveryImage";
import {DeliveryListStorage} from "./DeliveryListStorage";
import {DeliveryQueueItemTable} from "./DeliveryQueueItemTable";
import {DeliveryRecipientTypeDisplay} from "./DeliveryRecipientTypeDisplay";
import {DeliveryStateDialog} from "./DeliveryStateDialog";
import {DeliveryStateDisplay} from "./DeliveryStateDisplay";
import {DeliveryTags} from "./DeliveryTags";
import {DeliveryTypeDisplay} from "./DeliveryTypeDisplay";
import {ManualHandoverDialog} from "./ManualHandoverDialog";
import {OrderAttachmentsList} from "./OrderAttachmentsList";
import {OrderPositionsList} from "./OrderPositionsList";
import {DeliveryPrintView} from "./print/DeliveryPrintView";
import {ReceivingDepartmentComponent} from "./ReceivingDepartmentComponent";
import {RecipientAddDialog} from "./RecipientAddDialog";
import {RecipientEditDialog} from "./RecipientEditDialog";
import {SenderDisplay} from "./SenderDisplay";
import {WeightDisplay} from "./WeightDisplay";

interface DeliveryDetailsLegacyComponentProps {
	tenantId: string;
	deliveryId: string;
	navigate: NavigateFunction;
	tenant: Tenant;
	printViewConfig: IPrintViewConfig;
	user: User;
	isUser: boolean;
	isAdmin: boolean;
	isAdminLight: boolean;
	isRecipient: boolean;
	isSuperUser: boolean;
	languageContext: ReturnType<typeof useLocalization>;
}

interface DeliveryDetailsLegacyComponentState {
	handleDelete?: () => void;
	handleDeleteShipment?: () => void;
	handleTriggerCallback?: (deliveryState: DeliveryState) => void;
	handleTriggerNextState?: () => void;
	handleMarkChecked?: () => void;
	manualHandoverOpen: boolean;
	handleMarkShipped?: (input?: string[]) => void;
	delivery?: Delivery;
	recipientImageUrl?: string;
	signatureImageUrl?: string;
	notFound: boolean;
	eventQueue?: DeliveryQueueItem[];
	nextState?: DeliveryState;
	anchorEl: HTMLButtonElement | null;
	editRecipient: boolean;
	addRecipient: boolean;
	tags: string[];
	learnedTags?: string[];
	loading: boolean;
	deliveryListStorage: DeliveryListStorage;
	reminderDialogOpen: boolean;
	alertMessage?: string;
	alertColor?: AlertColor;
	deliveryDeleteFailed: boolean;
}

export class DeliveryDetailsLegacyComponent
	extends Component<DeliveryDetailsLegacyComponentProps, DeliveryDetailsLegacyComponentState>
{
	constructor(props: any) {
		super(props);
		let deliveryListStorage = new DeliveryListStorage();
		deliveryListStorage.load();
		this.state = {
			notFound: false,
			anchorEl: null,
			alertMessage: "",
			editRecipient: false,
			addRecipient: false,
			manualHandoverOpen: false,
			tags: [],
			deliveryListStorage: deliveryListStorage,
			loading: false,
			reminderDialogOpen: false,
			deliveryDeleteFailed: false,
		};
	}

	navigateToDelivery = async (id: string) => {
		if (id.length > 0) {
			this.setState({loading: true});
			this.props.navigate(`/${this.props.tenantId}/deliveries/${id}`);
			await this.load(this.props.tenant, id);
			this.setState({loading: false});
		}
	};

	prevDelivery = () => {
		let id: string = this.state.deliveryListStorage.prev(this.state.delivery?.id || "");
		this.navigateToDelivery(id);
	};

	nextDelivery = () => {
		let id: string = this.state.deliveryListStorage.next(this.state.delivery?.id || "");
		this.navigateToDelivery(id);
	};

	static computeNextState(current: DeliveryState): DeliveryState | undefined {
		switch (current) {
			case DeliveryState.Created:
				return DeliveryState.Stored;
			case DeliveryState.Stored:
				return DeliveryState.PickedUp;
			default:
				return undefined;
		}
	}

	prevNextEventHandler = (event: KeyboardEvent) => {
		if (document.activeElement == document.body) {
			if (event.code == "ArrowLeft") {
				this.prevDelivery();
			} else if (event.code == "ArrowRight") {
				this.nextDelivery();
			}
		}
	};

	async componentDidMount(): Promise<void> {
		document.addEventListener("keydown", this.prevNextEventHandler);

		const {tenant} = this.props;
		const {deliveryId} = this.props;
		await this.load(tenant, deliveryId);
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.prevNextEventHandler);
	}

	private async handleSendReminder(info?: string[]) {
		this.setState({loading: true});
		const {t} = this.props.languageContext;
		const {delivery} = this.state;
		if (delivery && delivery.id) {
			try {
				await DeliveriesProvider.sendReminder(delivery.tenantId, delivery.id, info ? info[0] : "");
				this.setState({reminderDialogOpen: false});
				this.setState({alertMessage: t("deliveries.reminder.success"), alertColor: "success"});
			} catch (ex) {
				this.setState({reminderDialogOpen: false});
				this.setState({alertMessage: t("deliveries.reminder.error"), alertColor: "error"});
			}
		}
		this.setState({loading: false});
	}

	private async load(tenant: Tenant, deliveryId: string) {
		if (tenant && deliveryId) {
			const deliveryPromise = DeliveriesProvider.get(tenant.centerId, deliveryId)
				.catch((e) => {
					this.setState({notFound: true});
					throw e;
				});
			const tagsPromise = DeliveriesProvider.computeDeliveryLearnedTags(tenant.centerId);
			const recipientImagePromise = DeliveriesProvider.getDeliveryImage(tenant.centerId, deliveryId, "Recipient")
				.catch(() => void 0);
			const signatureImagePromise = DeliveriesProvider.getDeliveryImage(tenant.centerId, deliveryId, "Signature")
				.catch(() => void 0);
			const [delivery, learnedTags, recipientImage, signatureImage] = await Promise.all(
				[
					deliveryPromise,
					tagsPromise,
					recipientImagePromise,
					signatureImagePromise,
				],
			);
			this.setState({
				delivery: delivery,
				recipientImageUrl: recipientImage && URL.createObjectURL(recipientImage),
				signatureImageUrl: signatureImage && URL.createObjectURL(signatureImage),
				nextState: DeliveryDetailsLegacyComponent.computeNextState(delivery.delivery.state),
				learnedTags,
			});
			DeliveriesProvider.eventQueue(tenant.centerId, deliveryId)
				.then((eventQueue) => this.setState({eventQueue: eventQueue}));
		} else {
			this.props.navigate(`/${this.props.tenantId}/deliveries`);
		}
	}

	private showMenu(anchorEl: HTMLButtonElement | null) {
		this.setState({
			anchorEl: anchorEl,
		});
	}

	private handleDelete() {
		const {tenant} = this.props;
		const {delivery} = this.state;
		if (tenant && delivery && delivery.id) {
			DeliveriesProvider.deleteDelivery(tenant.centerId, delivery.id).then(() => {
				this.setState({delivery: undefined});
				this.props.navigate(`/${this.props.tenantId}/deliveries`);
			})
				.catch(() => {
					this.setState({handleDelete: undefined});
					this.setState({deliveryDeleteFailed: true});
				});
		}
	}

	private handleDeleteShipment() {
		this.props.navigate(`/${this.props.tenantId}/shipments/${this.state.delivery?.shipment?.uuid}`);
	}

	private handleEditRecipient(
		recipient: Recipient[] | undefined,
		draft: boolean | undefined,
		manualHandoverRequired: boolean | undefined,
		destination: string | undefined,
	) {
		const {tenant} = this.props;
		const {delivery} = this.state;

		if (tenant && delivery && delivery.id && recipient) {
			DeliveriesProvider.setRecipients(
				tenant.centerId,
				delivery.id,
				recipient,
				destination,
				draft || false,
				manualHandoverRequired || false,
			).then((delivery) => {
				this.showMenu(null);
				this.setState({
					editRecipient: false,
					delivery: delivery,
				});
			});
		}
	}

	private handleAddRecipient(newRecipient: Recipient | undefined, all: boolean) {
		if (this.props.tenant && this.state.delivery && this.state.delivery.id && newRecipient) {
			if (all == false) {
				DeliveriesProvider.addRecipient(
					this.props.tenant.centerId,
					this.state.delivery.id,
					newRecipient,
				).then((delivery) => {
					this.showMenu(null);
					this.setState({
						addRecipient: false,
						delivery: delivery,
					});
				});
			} else if (this.state.delivery.recipients.length > 0) {
				let oldRecipient = this.state.delivery.recipients[0];
				OutOfOfficeProvider.redirect(
					this.props.tenant.centerId,
					oldRecipient,
					[newRecipient],
				).then(async (count) => {
					const {t} = this.props.languageContext;
					let mes = t("recipients.shipmentsRedirected", {no: count});
					// alert(mes); waiting for #452
					let d = await DeliveriesProvider.get(this.props.tenant.centerId, this.state.delivery?.id || "");
					this.showMenu(null);
					this.setState({
						addRecipient: false,
						delivery: d,
					});
				});
			}
		}
	}

	private setDelete(b: boolean) {
		this.showMenu(null);
		if (b) {
			if (this.state.delivery?.shipment) {
				this.setState({handleDeleteShipment: () => this.handleDeleteShipment()});
			} else {
				this.setState({handleDelete: () => this.handleDelete()});
			}
		} else {
			this.setState({handleDelete: undefined});
			this.setState({handleDeleteShipment: undefined});
		}
	}

	private handleTriggerCallback(selectedDeliveryState: DeliveryState) {
		const {t} = this.props.languageContext;
		const {tenant} = this.props;
		const {delivery} = this.state;
		if (tenant && delivery && delivery.delivery.deliveryId) {
			DeliveriesProvider.triggerCallback(tenant.centerId, delivery.delivery.deliveryId, selectedDeliveryState)
				.then((delivery) => {
					DeliveriesProvider.eventQueue(tenant.centerId, delivery.delivery.deliveryId).then(eventQueue => {
						this.setState({
							alertMessage: t("deliveries.triggerCallback.success"),
							alertColor: "success",
							handleTriggerCallback: undefined,
							delivery: delivery,
							nextState: DeliveryDetailsLegacyComponent.computeNextState(delivery.delivery.state),
							eventQueue: eventQueue,
						});
					});
				});
		}
	}

	private setTriggerCallback(b: boolean) {
		this.showMenu(null);
		if (b) {
			this.setState({
				handleTriggerCallback: (deliveryState: DeliveryState) => this.handleTriggerCallback(deliveryState),
			});
		} else {
			this.setState({handleTriggerCallback: undefined});
		}
	}

	private handleTriggerNextState() {
		const {t} = this.props.languageContext;
		const {tenant} = this.props;
		const {delivery} = this.state;
		if (tenant && delivery && delivery.delivery.deliveryId) {
			DeliveriesProvider.triggerNextState(tenant.centerId, delivery.delivery.deliveryId).then((delivery) => {
				DeliveriesProvider.eventQueue(tenant.centerId, delivery.delivery.deliveryId).then(eventQueue => {
					this.setState({
						alertMessage: t("deliveries.triggerCallback.success"),
						alertColor: "success",
						handleTriggerNextState: undefined,
						delivery: delivery,
						nextState: DeliveryDetailsLegacyComponent.computeNextState(delivery.delivery.state),
						eventQueue: eventQueue,
					});
				});
			});
		}
	}

	private handleMarkShipped(input: string[] | undefined) {
		const {t} = this.props.languageContext;
		const {tenant} = this.props;
		const {delivery} = this.state;
		if (tenant && delivery && delivery.delivery.deliveryId) {
			DeliveriesProvider.setShipped(
				tenant.centerId,
				delivery.delivery.deliveryId,
				input?.[0],
				input?.[1],
				input?.[2],
			).then((delivery) => {
				this.setState({
					handleMarkShipped: undefined,
				});
				DeliveriesProvider.eventQueue(tenant.centerId, delivery.delivery.deliveryId).then(eventQueue => {
					this.setState({
						alertMessage: t("deliveries.triggerCallback.success"),
						alertColor: "success",
						handleMarkShipped: undefined,
						delivery: delivery,
						eventQueue: eventQueue,
					});
				});
			});
		}
	}

	private setTriggerNextState(b: boolean) {
		this.showMenu(null);
		if (b) {
			this.setState({handleTriggerNextState: () => this.handleTriggerNextState()});
		} else {
			this.setState({handleTriggerNextState: undefined});
		}
	}

	private handleMarkChecked() {
		const {tenant, user} = this.props;
		const {delivery} = this.state;
		if (tenant && user && delivery && delivery.delivery.deliveryId) {
			DeliveriesProvider.markChecked(tenant.centerId, delivery.delivery.deliveryId, user.username)
				.then(() => {
					this.setState({
						handleMarkChecked: undefined,
						anchorEl: null,
					});
					DeliveriesProvider.get(tenant.centerId, delivery.delivery.deliveryId)
						.then((loaded) => {
							this.setState({
								delivery: loaded,
							});
						});
				});
		}
	}

	private setMarkChecked(b: boolean) {
		if (b) {
			this.setState({handleMarkChecked: () => this.handleMarkChecked()});
		} else {
			this.setState({handleMarkChecked: undefined});
		}
	}

	private onManualHandoverConfirmed() {
		this.setState({manualHandoverOpen: false});
		const {tenant} = this.props;
		const {delivery} = this.state;
		if (tenant && delivery) {
			DeliveriesProvider.get(tenant.centerId, delivery.delivery.deliveryId)
				.then((loaded) => {
					this.setState({
						delivery: loaded,
					});
					DeliveriesProvider.eventQueue(tenant.centerId, delivery.delivery.deliveryId).then((eventQueue) => {
						this.setState({
							eventQueue: eventQueue,
						});
					});
				});
		}
	}

	handleTags = (tags: string[]) => {
		const {delivery} = this.state;
		if (delivery) {
			this.setState({delivery: {...delivery, tags: tags}});
			DeliveriesProvider.setTags(this.props.tenant.centerId, delivery.delivery.deliveryId, tags);
		}
	};

	private setMarkShipped(b: boolean) {
		this.showMenu(null);
		if (b) {
			this.setState({handleMarkShipped: (input) => this.handleMarkShipped(input)});
		} else {
			this.setState({handleMarkShipped: undefined});
		}
	}

	private isPrint(field: Field): boolean {
		if (this.props.printViewConfig) {
			let p: Printable[] = this.props.printViewConfig.printable;
			let ret = p.find(obj => obj.key == field);
			return ret?.print ?? true;
		}
		return true;
	}

	private handleReadyToPrint() {
		const search = qs.parse(window.location.search, {ignoreQueryPrefix: true});
		const printLabel = search && search["printLabel"] == "true";
		if (printLabel) {
			window.addEventListener("afterprint", () => {
				const {delivery} = this.state;
				if (delivery) {
					this.props.navigate(`/${this.props.tenantId}/deliveries/${delivery.id}`);
				}
			});
			window.print();
		}
	}

	private computeActions(): ActionMenuItem[] {
		const {t} = this.props.languageContext;
		const actions: ActionMenuItem[] = [];
		const {isUser, isAdmin, isAdminLight, isSuperUser, isRecipient} = this.props;
		const {delivery} = this.state;

		if (delivery) {
			const printAction: ActionMenuItem = {
				label: t("print"),
				onClick: () => window.print(),
			};

			const checkedOption: ActionMenuItem = {
				label: t("deliveries.markChecked.title"),
				onClick: () => this.setMarkChecked(true),
			};

			const triggerCallbackOption: ActionMenuItem = {
				label: t("deliveries.triggerCallback.title"),
				onClick: () => this.setTriggerCallback(true),
			};

			const triggerNextStateOption: ActionMenuItem = {
				label: t("deliveries.triggerNextState.title"),
				onClick: () => this.setTriggerNextState(true),
			};

			const deleteOption: ActionMenuItem = {
				label: t("deliveries.delete.title"),
				onClick: () => this.setDelete(true),
			};

			const manualHandoverOption: ActionMenuItem = {
				label: t("deliveries.manualHandover.title"),
				onClick: () => this.setState({manualHandoverOpen: true}),
			};

			const markShippedOption: ActionMenuItem = {
				label: t("deliveries.shipped.title"),
				onClick: () => this.setMarkShipped(true),
			};

			const editOption: ActionMenuItem = {
				label: t("deliveries.edit.title"),
				onClick: () => this.editDelivery(true),
			};

			const redirectOption: ActionMenuItem = {
				label: t("deliveries.redirect.title"),
				onClick: () => this.addRecipient(true),
			};

			const reminderOption: ActionMenuItem = {
				label: t("deliveries.reminder.title"),
				onClick: () => this.remind(true),
			};

			if (delivery.delivery.state == DeliveryState.Created) {
				actions.push(printAction);
			}
			if (
				delivery.delivery.state === DeliveryState.Draft
				|| delivery.delivery.state === DeliveryState.Created
				|| delivery.delivery.state === DeliveryState.Stored
			) {
				// check if there is only 1 unknown recipient. An error arises if redirect would be enabled
				if (!(delivery.recipients.length == 1 && delivery.recipients[0].recipientName == "")) {
					actions.push(redirectOption);
				}
				if (!isRecipient) {
					actions.push(editOption);
				}
			}
			if (delivery.delivery.state == DeliveryState.PickedUp && delivery.delivery.checkedAt == undefined) {
				actions.push(checkedOption);
			}
			if (
				delivery.delivery.state === DeliveryState.Created
				|| delivery.delivery.state === DeliveryState.Cancelled
			) {
				actions.push(manualHandoverOption);
			}
			if (
				delivery.delivery.deliveryType == DeliveryType.Outbound
				&& delivery.delivery.state === DeliveryState.PickedUp
			) {
				actions.push(markShippedOption);
			}
			if (delivery.delivery.state != DeliveryState.Created) {
				actions.push(printAction);
			}
			if (delivery.metadata && delivery.metadata.callbackUrl) {
				actions.push(triggerCallbackOption);
			}
			if (
				delivery.delivery.state === DeliveryState.Stored
				|| (delivery.storage?.manualHandoverRequired && delivery.delivery.state == DeliveryState.Created)
			) {
				actions.push(reminderOption);
			}

			if (isSuperUser) {
				actions.push(triggerNextStateOption);
			}
			if (isUser || isAdminLight || isAdmin || isSuperUser) {
				actions.push(deleteOption);
			}
		}

		return actions;
	}

	render() {
		const {tenant, tenantId, user, printViewConfig} = this.props;
		const {t, e, hasKey} = this.props.languageContext;
		const {
			delivery,
			signatureImageUrl,
			recipientImageUrl,
			notFound,
			nextState,
			eventQueue,
			editRecipient,
			addRecipient,
			loading,
		} = this.state;
		if (notFound) {
			return (
				<NotFound
					errorMsg={t("notFound.title")}
					backToHomeMsg={t("notFound.home")}
					pathMsg={t("notFound.info")}
				/>
			);
		}
		const label = delivery?.order?.description || delivery?.order?.orderId || delivery?.id;
		if (delivery && !loading) {
			const orderDescription = (delivery.order && delivery.order.description)
				? delivery.order.description
				: delivery.id;
			const actions = this.computeActions();

			return (
				<WebSocketMessageConsumer
					tenantId={tenantId}
					path={`/deliveries/${delivery.id}`}
					onMessage={() => this.load(tenant, delivery.id)}
				>
					<Box display="block" displayPrint="none">
						<ContainerLayout>
							<HelmetTitleWrapper pageTitle={t("deliveries.singular")} />
							<Grid container spacing={3}>
								<Grid item xs={12}>
									<Breadcrumbs>
										<BreadcrumbRouterLink to={`/${tenantId}/deliveries`}>
											{t("deliveries.plural")}
										</BreadcrumbRouterLink>
										<BreadcrumbItem>{label}</BreadcrumbItem>
									</Breadcrumbs>
								</Grid>
								<Grid item xs={12}>
									<Grid container>
										<Grid item style={{flexGrow: 1}}>
											<Typography variant="h2">
												{`${t("deliveries.singular")}: ${label}`}
											</Typography>
										</Grid>
										<Grid item>
											<ActionsMenu actions={actions} />
											<Snackbar open={!!this.state.alertMessage}>
												<Alert severity={this.state.alertColor}>
													{this.state.alertMessage}
												</Alert>
											</Snackbar>
										</Grid>
									</Grid>
								</Grid>
								{delivery.shipment && (
									<Grid item xs={12}>
										<Alert
											severity="info"
											action={
												<Button
													onClick={() =>
														this.props.navigate(
															`/${this.props.tenantId}/shipments/${delivery.shipment?.uuid}`,
														)}
												>
													{t("deliveries.viewShipment")}
												</Button>
											}
										>
											{t("deliveries.shipmentDetected")}
										</Alert>
									</Grid>
								)}
								<Grid item xs={12}>
									<BoxedPaper>
										<Grid container spacing={gs}>
											<Grid item xs={12}>
												<Grid container spacing={gs}>
													<LabeledItem label={t("deliveries.deliveryState")}>
														<DeliveryStateDisplay deliveryState={delivery.delivery.state} />
													</LabeledItem>

													<LabeledItem label={t("deliveries.deliveryType")}>
														<DeliveryTypeDisplay
															deliveryType={delivery.delivery.deliveryType}
														/>
													</LabeledItem>
													{(delivery.delivery.state == DeliveryState.Created
														&& delivery.storage
														&& delivery.storage.manualHandoverRequired)
														&& (
															<Fragment>
																<LabeledItem
																	label={t("deliveries.manualHandover.title")}
																>
																	<Chip
																		label={t(
																			"deliveries.create.recipientStep.manualHandoverRequired",
																		)}
																		onClick={() =>
																			this.setState({manualHandoverOpen: true})}
																	/>
																</LabeledItem>
															</Fragment>
														)}
													<LabeledItem label={t("deliveries.condition")}>
														<DeliveryConditionDisplay value={delivery.delivery.condition} />
													</LabeledItem>
													<LabeledItem label={t("deliveries.deliveryId")}>
														<DeliveryIdDisplay id={delivery.id} />
													</LabeledItem>
													{delivery.delivery.createdAt && (
														<LabeledItem label={t("deliveries.createdAt")}>
															<DateComponent
																date={delivery.delivery.createdAt}
															/>
														</LabeledItem>
													)}
													{delivery.delivery.createdBy && (
														<LabeledItem label={t("deliveries.createdBy")}>
															<UserNameDisplay
																username={delivery.delivery.createdBy}
															/>
														</LabeledItem>
													)}
													{delivery.delivery.storedAt && (
														<LabeledItem label={t("deliveries.storedAt")}>
															<DateComponent date={delivery.delivery.storedAt} />
														</LabeledItem>
													)}
													{delivery.delivery.storedBy && (
														<LabeledItem label={t("deliveries.storedBy")}>
															<UserNameDisplay username={delivery.delivery.storedBy} />
														</LabeledItem>
													)}
													{delivery.delivery.allowPickupUntil && (
														<LabeledItem label={t("deliveries.allowPickupUntil")}>
															<DateComponent date={delivery.delivery.allowPickupUntil} />
														</LabeledItem>
													)}
													{delivery.delivery.reminderSentAt && (
														<LabeledItem label={t("deliveries.reminderSentAt")}>
															<DateComponent date={delivery.delivery.reminderSentAt} />
														</LabeledItem>
													)}
													{delivery.delivery.pickedUpAt && (
														<LabeledItem label={t("deliveries.pickedUpAt")}>
															<DateComponent date={delivery.delivery.pickedUpAt} />
														</LabeledItem>
													)}
													{delivery.delivery.pickedUpBy && (
														<LabeledItem label={t("deliveries.pickedUpBy")}>
															<UserNameDisplay username={delivery.delivery.pickedUpBy} />
														</LabeledItem>
													)}
													{(tenant && tenant.recipientPickupKeyExposed
														&& delivery.delivery.usedPickupKey)
														&& (
															<LabeledItem label={t("deliveries.usedPickupKey")}>
																<CodeDisplay code={delivery.delivery.usedPickupKey} />
															</LabeledItem>
														)}
													{delivery.delivery.checkedAt && (
														<LabeledItem label={t("deliveries.checkedAt")}>
															<DateComponent date={delivery.delivery.checkedAt} />
														</LabeledItem>
													)}
													{delivery.delivery.checkedBy && (
														<LabeledItem label={t("deliveries.checkedBy")}>
															<UserNameDisplay username={delivery.delivery.checkedBy} />
														</LabeledItem>
													)}
													{delivery.lastUpdatedAt && (
														<LabeledItem label={t("deliveries.lastUpdatedAt")}>
															<DateComponent
																date={delivery.lastUpdatedAt}
															/>
														</LabeledItem>
													)}
													{delivery.lastUpdatedBy && (
														<LabeledItem label={t("deliveries.lastUpdatedBy")}>
															<UserNameDisplay
																username={delivery.lastUpdatedBy}
															/>
														</LabeledItem>
													)}
												</Grid>
											</Grid>
											<Grid item xs={12}>
												<Grid container spacing={gs}>
													{delivery.storage?.storageCode
														&& (
															<LabeledItem label={t("deliveries.storageCode")}>
																<CodeDisplay
																	code={delivery.storage.storageCode}
																	inActive={delivery.delivery.state
																			!= DeliveryState.Created
																		&& delivery.delivery.state
																			!= DeliveryState.Reserved}
																/>
															</LabeledItem>
														)}
													{tenant.pickupCodeExposed && delivery.delivery.accessCode
														&& (
															<LabeledItem label={t("deliveries.pickupCode")}>
																<CodeDisplay
																	code={delivery.delivery.accessCode}
																	inActive={delivery.delivery.state
																			!= DeliveryState.Stored
																		&& delivery.delivery.state
																			!= DeliveryState.Returned}
																/>
															</LabeledItem>
														)}
													{(delivery.storage && delivery.storage.destination)
														&& (
															<LabeledItem label={t("cubes.boxes.singular")}>
																<DeliveryBoxComponent delivery={delivery} />
															</LabeledItem>
														)}
													{delivery.receivingDepartment
														&& (
															<LabeledItem label={t("deliveries.receivingDepartment")}>
																<ReceivingDepartmentComponent
																	bookmarkId={delivery.receivingDepartment}
																/>
															</LabeledItem>
														)}
													<Grid item>
														<DeliveryTags
															label={t("deliveries.tags")}
															placeholder={t("deliveries.addTag")}
															tags={delivery.tags}
															options={this.state.learnedTags}
															onChange={this.handleTags}
														/>
													</Grid>
												</Grid>
											</Grid>
											<Grid item xs={12}>
												<Grid container spacing={gs}>
													{delivery.delivery.sender && (
														<Grid item>
															<LabeledItem label={t("deliveries.sender")}>
																{delivery.delivery.sender}
															</LabeledItem>
														</Grid>
													)}
													{delivery.delivery.carrier && (
														<Grid item>
															<LabeledItem label={t("deliveries.carrier")}>
																{delivery.delivery.carrier}
															</LabeledItem>
														</Grid>
													)}
													{delivery.delivery.parcelId && (
														<Grid item>
															<LabeledItem label={t("deliveries.parcelId")}>
																{delivery.delivery.trackingUrl && (
																	<Link
																		href={delivery.delivery.trackingUrl}
																		target={delivery.delivery.trackingUrl}
																	>
																		<span style={{fontFamily: "monospace"}}>
																			{delivery.delivery.parcelId}
																		</span>
																	</Link>
																)}
																{!delivery.delivery.trackingUrl && (
																	<span style={{fontFamily: "monospace"}}>
																		<Grid container spacing={1}>
																			{delivery.delivery.parcelId.split(";")
																				.filter(token =>
																					token.length > 0
																				).map(parcelIdSingle => (
																					<Grid item key={parcelIdSingle}>
																						<Chip
																							label={parcelIdSingle}
																						/>
																					</Grid>
																				))}
																		</Grid>
																	</span>
																)}
															</LabeledItem>
														</Grid>
													)}
													{(delivery.delivery.weight != undefined
														&& delivery.delivery.weight > 0)
														&& (
															<Grid item>
																<LabeledItem label={t("deliveries.weight")}>
																	<WeightDisplay weight={delivery.delivery.weight} />
																</LabeledItem>
															</Grid>
														)}
													{delivery.delivery.units && (
														<Grid item>
															<LabeledItem label={t("deliveries.units")}>
																{delivery.delivery.units}
															</LabeledItem>
														</Grid>
													)}
													{delivery.delivery.numberOfItems > 0 && (
														<Grid item>
															<LabeledItem label={t("deliveries.numberOfItems")}>
																{delivery.delivery.numberOfItems}
															</LabeledItem>
														</Grid>
													)}
													{delivery.delivery.handedOverTo
														&& (
															<Fragment>
																<LabeledItem
																	label={t("deliveries.manualHandover.title")}
																>
																	{delivery.delivery.handedOverTo}
																</LabeledItem>
															</Fragment>
														)}
													{delivery.employeeId
														&& (
															<Fragment>
																<LabeledItem
																	label={t("deliveries.manualHandover.employeeId")}
																>
																	{delivery.employeeId}
																</LabeledItem>
															</Fragment>
														)}
													{delivery.deleteAfter && (
														<Grid item>
															<LabeledData label={t("recipients.deleteAfter")}>
																<TemporalFormat
																	value={tryParsePlainDate(delivery.deleteAfter)}
																/>
															</LabeledData>
														</Grid>
													)}
													{delivery.deleteAfterDeliveryType && (() => {
														const translationKey =
															`recipients.deleteAfterDeliveryType.${delivery.deleteAfterDeliveryType}`;
														return (
															<Grid item>
																<LabeledData
																	label={t("recipients.deleteAfterDeliveryType.text")}
																>
																	<Typography>
																		{hasKey(translationKey)
																			? t(translationKey as any)
																			: ""}
																	</Typography>
																</LabeledData>
															</Grid>
														);
													})()}
													{signatureImageUrl && (
														<Fragment>
															<LabeledItem label={t("deliveries.signature")}>
																<img
																	src={signatureImageUrl}
																	style={{maxWidth: "100%", maxHeight: "250px"}}
																/>
															</LabeledItem>
														</Fragment>
													)}
												</Grid>
											</Grid>
											{delivery.deliveryDispatchDetails && (
												<Grid item xs={12}>
													<Grid container spacing={gs}>
														{delivery.deliveryDispatchDetails.costCenter && (
															<LabeledItem label={t("dispatch.details.costCenter")}>
																{delivery.deliveryDispatchDetails
																	.costCenter}
															</LabeledItem>
														)}
														{delivery.deliveryDispatchDetails.dispatchDetailsType
															&& (
																<LabeledItem label={t("dispatch.details.type")}>
																	{e(
																		"dispatch.details.types",
																		delivery.deliveryDispatchDetails
																			.dispatchDetailsType,
																	)}
																</LabeledItem>
															)}
														{delivery.deliveryDispatchDetails.dispatchDate && (
															<LabeledItem label={t("dispatch.details.dispatchDate")}>
																{delivery.deliveryDispatchDetails.dispatchDate}
															</LabeledItem>
														)}
														{delivery.deliveryDispatchDetails.deliveryDate && (
															<LabeledItem label={t("dispatch.details.deliveryDate")}>
																{delivery.deliveryDispatchDetails.deliveryDate}
															</LabeledItem>
														)}
														{delivery.deliveryDispatchDetails.deliveryTime && (
															<LabeledItem label={t("dispatch.details.deliveryTime")}>
																{delivery.deliveryDispatchDetails.deliveryTime}
															</LabeledItem>
														)}
														{delivery.deliveryDispatchDetails.numOfParcels && (
															<LabeledItem label={t("dispatch.details.numOfParcels")}>
																{delivery.deliveryDispatchDetails.numOfParcels}
															</LabeledItem>
														)}
													</Grid>
												</Grid>
											)}
										</Grid>
									</BoxedPaper>
								</Grid>

								{delivery.sender && (
									<Grid item xs={12} sm={6} md={6}>
										<Grid container spacing={gs}>
											<Grid item xs={12}>
												<Typography variant="h5" gutterBottom>
													{t("deliveries.sender")}
												</Typography>
											</Grid>
											<Grid item xs={12}>
												<SenderDisplay sender={delivery.sender}></SenderDisplay>
											</Grid>
										</Grid>
									</Grid>
								)}

								{((delivery.recipients && delivery.recipients.length > 0)
									|| delivery.externalRecipient) && (
									<Grid item xs={12} sm={6} md={6}>
										<Grid container spacing={gs}>
											<Grid item xs={12}>
												<Typography variant="h5" gutterBottom>
													{t("deliveries.recipients.plural")}
												</Typography>
											</Grid>
											{(delivery.recipients.length > 0) && (
												<Grid item xs={12}>
													<Paper>
														<List>
															{delivery.recipients.map((r) => (
																<ListItem
																	key={r.recipientName}
																	secondaryAction={r.pickedUp
																		? (
																			<Chip
																				color="secondary"
																				label={t("deliveries.pickedUp")}
																			/>
																		)
																		: undefined}
																>
																	<ListItemText
																		primary={r.recipientName
																			|| t("deliveries.recipients.unknown")}
																		secondary={recipientSecondaryText(
																			r,
																			Boolean(
																				tenant
																					&& tenant.recipientPickupKeyExposed,
																			),
																		)}
																	/>{" "}
																	{r.recipientType
																		&& (
																			<DeliveryRecipientTypeDisplay
																				deliveryRecipientType={r.recipientType}
																			/>
																		)}
																</ListItem>
															))}
														</List>
													</Paper>
												</Grid>
											)}
											{delivery.externalRecipient
												&& (
													<Grid item xs={12}>
														<BoxedPaper>
															<Grid container spacing={1}>
																{delivery.externalRecipient.company
																	&& (
																		<Grid item xs={12}>
																			<Typography variant="body1">
																				{delivery.externalRecipient.company}
																			</Typography>
																		</Grid>
																	)}
																{delivery.externalRecipient.name
																	&& (
																		<Grid item xs={12}>
																			<Typography variant="body1">
																				{delivery.externalRecipient.name}
																			</Typography>
																		</Grid>
																	)}
																{delivery.externalRecipient.line1
																	&& (
																		<Grid item xs={12}>
																			<Typography variant="body1">
																				{delivery.externalRecipient.line1}
																			</Typography>
																		</Grid>
																	)}
																{delivery.externalRecipient.line2
																	&& (
																		<Grid item xs={12}>
																			<Typography variant="body1">
																				{delivery.externalRecipient.line2}
																			</Typography>
																		</Grid>
																	)}
																<Grid item xs={12}>
																	<Typography variant="body1">
																		{`${delivery.externalRecipient.zip} ${delivery.externalRecipient.city}`}
																	</Typography>
																</Grid>
																<Grid item xs={12}>
																	<Typography variant="body1">
																		{delivery.externalRecipient.country}
																	</Typography>
																</Grid>
																{delivery.externalRecipient.email && (
																	<Grid item xs={12}>
																		<Typography variant="body1">
																			{delivery.externalRecipient.email}
																		</Typography>
																	</Grid>
																)}
																{delivery.externalRecipient.phone && (
																	<Grid item xs={12}>
																		<Typography variant="body1">
																			{delivery.externalRecipient.phone}
																		</Typography>
																	</Grid>
																)}
															</Grid>
														</BoxedPaper>
													</Grid>
												)}
										</Grid>
									</Grid>
								)}
								<Grid item xs={12} sm={6} md={6}>
									<Stack spacing={gs}>
										<Typography variant="h5" gutterBottom>
											{t("deliveries.updateHistory")}
										</Typography>
										<DeliveryAuditLog deliveryId={delivery.id} />
									</Stack>
								</Grid>
								{(delivery.order
									&& (delivery.order.orderId || delivery.order.deliveryNoteId
										|| delivery.order.description
										|| (delivery.order.orderPositions && delivery.order.orderPositions.length > 0)))
									&& (
										<Grid item xs={12} sm={6} md={6}>
											<Grid container spacing={gs}>
												<Grid item xs={12}>
													<Typography variant="h5" gutterBottom>
														{t("deliveries.order.singular")}
													</Typography>
												</Grid>
												<Grid item xs={12}>
													<Paper>
														<Box p={2}>
															<Grid container spacing={gs}>
																<Grid item xs={12}>
																	<Grid container spacing={gs}>
																		{delivery.order.description
																			&& (
																				<LabeledItem
																					label={t(
																						"deliveries.order.description",
																					)}
																				>
																					{delivery.order.description}
																				</LabeledItem>
																			)}
																		{delivery.order.deliveryNoteId
																			&& (
																				<LabeledItem
																					label={t(
																						"deliveries.order.deliveryNoteId",
																					)}
																				>
																					{delivery.order.deliveryNoteId}
																				</LabeledItem>
																			)}
																		{delivery.order.orderId
																			&& (
																				<LabeledItem
																					label={t(
																						"deliveries.order.orderId",
																					)}
																				>
																					{delivery.order.orderId}
																				</LabeledItem>
																			)}
																	</Grid>
																</Grid>
															</Grid>
														</Box>
														{(delivery.order.orderPositions
															&& delivery.order.orderPositions.length > 0)
															&& (
																<OrderPositionsList
																	orderPositions={delivery.order.orderPositions}
																/>
															)}
													</Paper>
												</Grid>
											</Grid>
										</Grid>
									)}
								{(delivery.order && delivery.order.attachments && delivery.order.attachments.length > 0)
									&& (
										<Grid item xs={12} sm={6} md={6}>
											<Grid container spacing={gs}>
												<Grid item xs={12}>
													<Typography variant="h5" gutterBottom>
														{t("deliveries.attachments.plural")}
													</Typography>
												</Grid>
												<Grid item xs={12}>
													<Paper>
														<OrderAttachmentsList
															attachments={delivery.order.attachments}
														/>
													</Paper>
												</Grid>
											</Grid>
										</Grid>
									)}
								{recipientImageUrl && (
									<Grid item xs={12} sm={6} md={6}>
										<Grid container spacing={gs}>
											<Grid item xs={12}>
												<Typography variant="h5" gutterBottom>
													{t("deliveries.image")}
												</Typography>
											</Grid>
											<Grid item xs={12}>
												<Paper>
													<DeliveryImage
														src={recipientImageUrl}
													/>
												</Paper>
											</Grid>
										</Grid>
									</Grid>
								)}
								{(delivery.storage
									&& (delivery.storage.hazardousGoods || delivery.storage.storageTimeMax
										|| delivery.delivery.allowPickupUntil))
									&& (
										<Grid item xs={12} sm={6} md={6}>
											<Grid container spacing={gs}>
												<Grid item xs={12}>
													<Typography variant="h5" gutterBottom>
														{t("deliveries.storage.singular")}
													</Typography>
												</Grid>
												<Grid item xs={12}>
													<BoxedPaper>
														<Grid container spacing={gs}>
															{delivery.storage.hazardousGoods
																&& (
																	<LabeledItem
																		label={t("deliveries.storage.hazardousGoods")}
																		grow
																	>
																		{delivery.storage.hazardousGoods}
																	</LabeledItem>
																)}
															{delivery.storage.storageTimeMax
																&& (
																	<LabeledItem
																		label={t("deliveries.storage.storageTimeMax")}
																	>
																		{`${delivery.storage.storageTimeMax} ${
																			t("deliveries.storage.hours")
																		}`}
																	</LabeledItem>
																)}
															{delivery.delivery.allowPickupUntil
																&& (
																	<LabeledItem
																		label={t("deliveries.allowPickupUntil")}
																	>
																		<DateComponent
																			date={delivery.delivery.allowPickupUntil}
																			display={Display.Timestamp}
																		/>
																	</LabeledItem>
																)}
														</Grid>
													</BoxedPaper>
												</Grid>
											</Grid>
										</Grid>
									)}
								{(delivery.storage?.storageKey
									|| (delivery.metadata
										&& (delivery.metadata.foreignId || delivery.metadata.callbackUrl)))
									&& (
										<Grid item xs={12} sm={6} md={6}>
											<Grid container spacing={gs}>
												<Grid item xs={12}>
													<Typography variant="h5" gutterBottom>
														{t("deliveries.thirdParty.singular")}
													</Typography>
												</Grid>
												<Grid item xs={12}>
													<BoxedPaper>
														<Grid container spacing={gs}>
															{delivery.storage?.storageKey
																&& (
																	<LabeledItem
																		label={t("deliveries.thirdParty.storageKey")}
																	>
																		{delivery.storage.storageKey}
																	</LabeledItem>
																)}
															{delivery.metadata?.foreignId
																&& (
																	<LabeledItem
																		label={t("deliveries.thirdParty.foreignId")}
																	>
																		{delivery.metadata.foreignId}
																	</LabeledItem>
																)}
															{delivery.metadata?.callbackUrl
																&& (
																	<LabeledItem
																		label={t("deliveries.thirdParty.callbackUrl")}
																	>
																		<span title={delivery.metadata.callbackUrl}>
																			{shorten(delivery.metadata.callbackUrl, 50)}
																		</span>
																	</LabeledItem>
																)}
														</Grid>
													</BoxedPaper>
												</Grid>
											</Grid>
										</Grid>
									)}
								{(delivery.metadata && delivery.metadata.callbackUrl && eventQueue)
									&& (
										<Fragment>
											<Grid item xs={12}>
												<Typography variant="h5" gutterBottom>
													{t("deliveries.eventQueue.plural")}
												</Typography>
											</Grid>
											<Grid item xs={12}>
												<Paper>
													<DeliveryQueueItemTable eventQueue={eventQueue} />
												</Paper>
											</Grid>
										</Fragment>
									)}
								{(delivery?.returnDelivery)
									&& (
										<Grid item xs={12} sm={6} md={6}>
											<Grid container spacing={gs}>
												<Grid item xs={12}>
													<Typography variant="h5" gutterBottom>
														{t("returns.singular")}
													</Typography>
												</Grid>
												<Grid item xs={12}>
													<BoxedPaper>
														<Grid container spacing={gs}>
															{delivery.returnDelivery.deliveryNoteId
																&& (
																	<LabeledItem label={t("returns.deliveryNoteId")}>
																		{delivery.returnDelivery.deliveryNoteId}
																	</LabeledItem>
																)}
															{delivery.returnDelivery.reasonForReturn
																&& (
																	<LabeledItem label={t("returns.reasonForReturn")}>
																		{t(
																			"returns."
																			+ delivery.returnDelivery
																				.reasonForReturn as any,
																		)}
																	</LabeledItem>
																)}
															{delivery.returnDelivery.customerId
																&& (
																	<LabeledItem label={t("returns.customerId")}>
																		{delivery.returnDelivery.customerId}
																	</LabeledItem>
																)}
															{delivery.returnDelivery.positionNumbers
																&& (
																	<LabeledItem label={t("returns.positionNumbers")}>
																		{delivery.returnDelivery.positionNumbers}
																	</LabeledItem>
																)}
															{delivery.returnDelivery.description
																&& (
																	<LabeledItem label={t("returns.additionalInfo")}>
																		{delivery.returnDelivery.description}
																	</LabeledItem>
																)}
														</Grid>
													</BoxedPaper>
												</Grid>
											</Grid>
										</Grid>
									)}
								{delivery?.notes && (
									<Grid item xs={12} sm={6}>
										<Grid container spacing={gs}>
											<Grid item xs={12}>
												<Typography variant="h5" gutterBottom>
													{t("deliveries.manualHandover.notes")}
												</Typography>
											</Grid>
											<Grid item xs={12}>
												<BoxedPaper>
													<Grid container spacing={gs}>
														<Grid item xs={12}>
															<Typography>{delivery.notes}</Typography>
														</Grid>
													</Grid>
												</BoxedPaper>
											</Grid>
										</Grid>
									</Grid>
								)}
							</Grid>
							<Grid item xs={12} sm={12} md={12}>
								<Grid container spacing={gs}>
									<Grid item xs={12} style={{paddingTop: "7em"}}>
										<Typography align="center">
											<ButtonGroup>
												<Tooltip title={t("deliveries.previousTooltip")}>
													<Button
														onClick={() => {
															this.prevDelivery();
														}}
														disabled={this.state.deliveryListStorage.prev(
															this.state.delivery?.id || "",
														).length == 0}
													>
														{t("previous")}
													</Button>
												</Tooltip>
												<Tooltip title={t("deliveries.nextTooltip")}>
													<Button
														onClick={() => {
															this.nextDelivery();
														}}
														disabled={this.state.deliveryListStorage.next(
															this.state.delivery?.id || "",
														).length == 0}
													>
														{t("next2")}
													</Button>
												</Tooltip>
											</ButtonGroup>
										</Typography>
									</Grid>
								</Grid>
							</Grid>
							<ConfirmDialog
								open={this.state.handleMarkChecked != undefined}
								title={t("deliveries.markChecked.title")}
								message={t("deliveries.markChecked.info", {deliveryId: label || "--"})}
								resolve={this.state.handleMarkChecked}
								reject={() => this.setMarkChecked(false)}
							/>
							<ConfirmDialog
								open={this.state.handleDelete != undefined}
								title={t("deliveries.delete.title")}
								message={t("deliveries.delete.info", {deliveryId: orderDescription})}
								resolve={this.state.handleDelete}
								reject={() => this.setDelete(false)}
							/>
							<ConfirmDialog
								open={this.state.handleDeleteShipment != undefined}
								title={t("deliveries.deleteShipment.title")}
								message={t("deliveries.deleteShipment.info", {deliveryId: orderDescription})}
								confirmLabel={t("deliveries.deleteShipment.confirm")}
								resolve={this.state.handleDeleteShipment}
								reject={() => this.setDelete(false)}
							/>
							<ConfirmDialog
								open={this.state.reminderDialogOpen}
								title={t("deliveries.reminder.title")}
								message={t("deliveries.reminder.info", {deliveryId: orderDescription})}
								confirmLabel={t("deliveries.reminder.sendReminder")}
								textFieldLabel={[t("deliveries.reminder.customMessage")]}
								resolve={(info?: string[]) => this.handleSendReminder(info)}
								reject={() => this.setState({reminderDialogOpen: false})}
							/>

							<DeliveryStateDialog
								title={t("deliveries.triggerCallback.title")}
								message={t("deliveries.triggerCallback.info", {
									state: delivery.delivery.state,
									deliveryId: orderDescription,
									url: delivery.metadata?.callbackUrl || "no-url",
								})}
								resolve={this.state.handleTriggerCallback}
								reject={() => this.setTriggerCallback(false)}
								deliveryState={delivery.delivery.state}
							/>
							{nextState
								&& (
									<ConfirmDialog
										open={this.state.handleTriggerNextState != undefined}
										title={t("deliveries.triggerNextState.title")}
										message={t("deliveries.triggerNextState.info", {
											deliveryId: orderDescription,
											state: nextState,
										})}
										resolve={this.state.handleTriggerNextState}
										reject={() => this.setTriggerNextState(false)}
									/>
								)}
							{(delivery && tenant)
								&& (
									<ManualHandoverDialog
										open={this.state.manualHandoverOpen}
										options={tenant.manualHandoverOptions}
										tenantId={tenant.centerId}
										deliveryIds={[delivery.id]}
										delivery={delivery}
										orderDescription={orderDescription}
										onConfirm={() => this.onManualHandoverConfirmed()}
										onCancel={() => this.setState({manualHandoverOpen: false})}
									/>
								)}
							<ConfirmDialog
								open={this.state.handleMarkShipped != undefined}
								title={t("deliveries.shipped.title")}
								message={t("deliveries.shipped.info", {deliveryId: orderDescription})}
								textFieldLabel={[
									t("deliveries.parcelId"),
									t("deliveries.carrier"),
									t("deliveries.trackingUrl"),
								]}
								resolve={this.state.handleMarkShipped}
								reject={() => this.setMarkShipped(false)}
							/>
							<RecipientEditDialog
								cube={delivery.storage?.destination}
								open={Boolean(editRecipient && delivery && delivery.recipients)}
								recipient={(delivery && delivery.recipients && delivery.recipients.length > 0)
									? this.state.delivery?.recipients[0]
									: {recipientName: ""}}
								draft={delivery?.delivery.state === DeliveryState.Draft}
								manualHandoverRequired={this.state.delivery?.storage?.manualHandoverRequired || false}
								destinationVisible={delivery?.delivery.state != DeliveryState.Stored}
								onCancel={() => this.setState({editRecipient: false, anchorEl: null})}
								onSave={(recipient, draft, manualHandoverRequired, destination) => {
									if (recipient) {
										delivery.recipients[0] = recipient;
										this.handleEditRecipient(
											delivery.recipients,
											draft,
											manualHandoverRequired,
											destination,
										);
									}
								}}
							/>
							<RecipientAddDialog
								open={Boolean(addRecipient && delivery && delivery.recipients)}
								recipient={(delivery && delivery.recipients && delivery.recipients.length > 0)
									? this.state.delivery?.recipients[0]
									: {recipientName: ""}}
								showAll={delivery.recipients.length == 1}
								onCancel={() => this.setState({addRecipient: false, anchorEl: null})}
								onSave={(recipient, all: boolean) =>
									this.handleAddRecipient(recipient, all && delivery.recipients.length == 1)}
							/>
							<Dialog
								open={this.state.deliveryDeleteFailed}
							>
								<DialogTitle>
									{t("deliveries.delete.failed")}
								</DialogTitle>
								<DialogActions>
									<Button
										variant="contained"
										onClick={() => this.setState({deliveryDeleteFailed: false})}
									>
										OK
									</Button>
								</DialogActions>
							</Dialog>
						</ContainerLayout>
					</Box>
					<Box display="none" displayPrint="block" id="printBox">
						<DeliveryPrintView
							delivery={delivery}
							printViewConfig={printViewConfig}
							onReadyToPrint={() => this.handleReadyToPrint()}
						/>
					</Box>
				</WebSocketMessageConsumer>
			);
		} else {
			return <Loading />;
		}
	}

	private editDelivery(b: boolean) {
		this.setState({editRecipient: true});
	}
	private addRecipient(b: boolean) {
		this.setState({addRecipient: true});
	}

	private remind(b: boolean) {
		this.setState({reminderDialogOpen: true});
	}
}
